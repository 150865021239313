import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Button, CollapseBox, Content, Item } from './styles';

const Menu = ({ uri }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => setIsOpen(($) => !$), []);

  useEffect(() => {
    setIsOpen(($) => ($ ? !$ : $));
  }, [uri]);

  return (
    <>
      <Button onClick={handleClick}>{isOpen ? 'Zurück' : 'Menu'}</Button>
      <CollapseBox $isOpen={isOpen}>
        <Content>
          <div>
            <Item to="/" disabled={uri === '/'}>
              Verein
            </Item>
            <Item to="/app/" disabled={uri === '/app/'}>
              App
            </Item>
            <Item to="/karriere/" disabled={uri === '/karriere/'}>
              Karriere
            </Item>
            <Item to="/goenner/schritt-1/" disabled={uri.includes('/goenner/')}>
              Gönner werden
            </Item>
          </div>
          <div>
            <Item to="/kontakt/" disabled={uri === '/kontakt/'} $isBottom>
              Kontakt
            </Item>
            <Item to="/statuten/" disabled={uri === '/statuten/'} $isBottom>
              Statuten
            </Item>
            <Item to="/datenschutz/" disabled={uri === '/datenschutz/'} $isBottom>
              Datenschutz
            </Item>
            <Item to="/impressum/" disabled={uri === '/impressum/'} $isBottom>
              Impressum
            </Item>
          </div>
        </Content>
      </CollapseBox>
    </>
  );
};

Menu.propTypes = { uri: PropTypes.string.isRequired };

export default Menu;
