import React from 'react';
import PropTypes from 'prop-types';

import Menu from './Menu';
import { Container, Item } from './styles';

const Header = ({ uri }) => (
  <Container>
    <Menu uri={uri} />
    <Item to="/" disabled={uri === '/'}>
      Verein
    </Item>
    <Item to="/app/" disabled={uri === '/app/'}>
      App
    </Item>
    <Item to="/karriere/" disabled={uri === '/karriere/'}>
      Karriere
    </Item>
    <Item to="/goenner/schritt-1/" disabled={uri.includes('/goenner/')} isReverse>
      Gönner/in werden
    </Item>
  </Container>
);

Header.propTypes = { uri: PropTypes.string.isRequired };

export default Header;
