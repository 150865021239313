import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { palette, fonts } from '@/theme';

export const Container = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  z-index: 100;
  display: flex;
  align-items: center;
  background-color: ${palette.white};
  @media (max-width: 991px) {
    height: 30px;
  }
`;

export const Item = styled(({ isReverse, ...rest }) => <Link {...rest} />)`
  display: flex;
  align-items: center;
  width: 25%;
  height: 60px;
  box-shadow: ${({ disabled }) => (disabled ? 'inset 0 0 0 5px' : '-2px 0 #000, inset 0 -2px #000')};
  text-decoration: none;
  padding: 0 22px;
  ${({ isReverse }) => (isReverse ? fonts.text : fonts.smallText22)}
  ${({ isReverse, disabled }) =>
    isReverse &&
    `
      min-width: 360px;
      background-color: ${disabled ? palette.white : palette.black};
      color: ${disabled ? palette.black : palette.white};
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
  @media (max-width: 991px) {
    ${({ isReverse, disabled }) =>
      isReverse
        ? `
        width: 50%;
        min-width: 0;
        height: 30px;
        ${fonts.smallText22}
        box-shadow: ${disabled ? 'inset 0 0 0 3px' : 'none'};
        padding: 0 8px;
      `
        : 'display: none;'}
  }
`;
